import axios from "axios";
import Url from "../utils/Urls";
import getError from "../utils/GetError";

export const getAllClass = (setData) => {
  axios
    .get(Url + "/staff/class")
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getSingleClass = (id, setData) => {
  axios
    .get(Url + `/staff/class/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const addNewClass = (data, openNotificationWithIcon, setData) => {
  axios
    .post(Url + "/staff/class", data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Grade added Successfully"
      );
      getAllClass(setData);
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const updateClassInfo = (
  id,
  data,
  openNotificationWithIcon,
  setData,
  reset
) => {
  axios
    .put(Url + `/staff/class/${id}`, data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Grade updated Successfully"
      );
      getAllClass(setData);
      reset();
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

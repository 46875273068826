import React from "react";
import { RadialBar } from "@ant-design/charts";
import theme from "../../utils/Theme";

export default function RatioJade(props) {
  var data = [
    {
      name: "Teacher",
      star: props.teacher,
    },
    {
      name: "Student",
      star: props.student,
    },
  ];
  var config = {
    data: data,
    xField: "name",
    yField: "star",
    maxAngle: 270,
    radius: 0.9,
    innerRadius: 0.2,
    tooltip: {
      formatter: function formatter(datum) {
        return {
          name: "Count",
          value: datum.star,
        };
      },
    },
    colorField: "name",
    color: function color(_ref) {
      var name = _ref.name;
      if (name === "Student") {
        return theme.color;
      }
      return "#2f2e41";
    },
  };
  return <RadialBar {...config} style={{ height: "200px" }} />;
}
